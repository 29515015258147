import * as React from "react"
import Container from "components/container"
import styled from "@emotion/styled"
import { getColorScheme, theme as themeCommon } from "common/colorScheme"
import { css } from "@emotion/react"
import { mediaBreakpoint, BreakPoints } from "../../settings/breakpoints"
import { H2, P } from "../headings"
import Button, { ButtonBase } from "../button"
import Media from "../Media"
import { SectionMediaAlign, ISectionProps, MediaSize, SectionLayoutAlign, IThemeValue } from "./section.model"
import { elementData, formatMultilineText } from "./utils"
import { getSizeStyles, useTitleSize } from "./TitleSizeContext"
import Card from "./Card/Card"
import { useSectionTheme } from "./SectionThemeContext"

interface IAdditionalTextProps {
	sectionTheme: IThemeValue
}

const containerStyles = css`
	display: block;
	padding-top: 4.8rem;
	padding-bottom: 4.8rem;
	${mediaBreakpoint(BreakPoints.SM)} {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding-top: 0;
		padding-bottom: 0;
	}
`

const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;

	${ButtonBase} {
		width: initial;
		&[data-variant="hollow"] {
			width: initial;
		}
	}
`

const buttonStyles = css`
	margin-right: 2.4rem;
`

const Cover = styled.div`
	display: flex;
	content-visibility: initial;
	flex-direction: column;
	${mediaBreakpoint(BreakPoints.SM)} {
		padding: 60px 0;
		align-items: center;
	}
`

const TextContainer = styled.div`
	flex-basis: 42%;
`

const MediaContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	flex-basis: 100%;
	max-width: 100%;
	${mediaBreakpoint(BreakPoints.SM)} {
		flex-basis: 54%;
		max-width: 54%;
		margin: 0;
	}
`

const alignContentFirsttStyles = css`
	${MediaContainer} {
		margin-top: 3.2rem;
	}

	${mediaBreakpoint(BreakPoints.SM)} {
		${TextContainer} {
			order: 1;
		}
		${MediaContainer} {
			order: 2;
			margin-top: 0;
		}
	}
`

const alignMediaFirstStyles = css`
	${MediaContainer} {
		margin-top: 3.2rem;
		margin-bottom: 1.6rem;
		order: 1;
	}

	${TextContainer} {
		order: 2;
	}

	${mediaBreakpoint(BreakPoints.SM)} {
		${MediaContainer} {
			margin-bottom: 0;
			margin-top: 0;
		}
	}
`

export const AdditionalText = styled.div<IAdditionalTextProps>`
	width: 100%;
	margin-top: 20px;
	p {
		font-size: 1rem;
		line-height: 2.4rem;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: ${themeCommon.spacing(8)};
		img {
			margin-top: ${themeCommon.spacing(1)};
		}

		${({ sectionTheme }) =>
		sectionTheme &&
			css`
				color: ${themeCommon.color.white};
			`}
		${mediaBreakpoint(BreakPoints.SM)} {
			font-size: 1.6rem;
			line-height: 3.2rem;
			align-items: center;
			flex-direction: row;
			margin-bottom: initial;
			img {
				margin-left: ${themeCommon.spacing(4.5)};
				margin-top: initial;
			}
		}
	}
`

const cardsContainerStyles = css`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-top: 0rem;
	&:empty {
		display: none;
	}
	${mediaBreakpoint(BreakPoints.SM)} {
		padding-top: 4rem;
		margin: 0 0 -3.2rem;
		> div {
			flex-basis: calc(33.333% - 60px);
		}
	}
`

const MediaWrapper = styled.div`
	max-width: 100%;
	width: auto;
`

const layoutAlignStyles = {
	[SectionLayoutAlign.ContentFirst]: alignContentFirsttStyles,
	[SectionLayoutAlign.MediaFirst]: alignMediaFirstStyles,
}

interface Props extends ISectionProps {}

const getMediaSize = (size: MediaSize) => {
	switch (size) {
	case MediaSize.Small:
		return css`
				width: 350px;
			`
	case MediaSize.Medium:
		return css`
				width: 500px;
			`
	case MediaSize.Full:
		return css`
				${mediaBreakpoint(BreakPoints.SM)} {
					margin: -60px 0;
				}
			`
	default:
		return css``
	}
}

const getMediaAlign = (alignment: SectionMediaAlign) => {
	switch (alignment) {
	case SectionMediaAlign.Left:
		return css`
				align-items: flex-start;
			`
	case SectionMediaAlign.Center:
		return css`
				align-items: center;
			`
	case SectionMediaAlign.Right:
	default:
		return css`
				align-items: flex-end;
			`
	}
}

const ImageRight: React.FC<Props> = ({
	colorScheme,
	title,
	titleLevel,
	description,
	buttons,
	media,
	mediaAlign,
	mediaSize,
	layoutAlignment,
	customStyle,
	cards,
	additionalText,
	subTitle
}) => {
	const titleSize = useTitleSize()
	const theme = useSectionTheme()

	return (
		<section css={customStyle}>
			<Cover css={getColorScheme(colorScheme)} data-element={elementData.cover}>
				<Container css={[containerStyles, layoutAlignStyles[layoutAlignment]]} data-element={elementData.container}>
					<TextContainer data-element={elementData.textContainer}>
						<P data-element="subtitle">{subTitle}</P>
						<H2 css={getSizeStyles(titleSize)} sectionTheme={theme} as={titleLevel || "h2"}>
							{formatMultilineText(title)}
						</H2>
						<P sectionTheme={theme}>{description}</P>
						<ButtonsContainer>
							{(buttons || []).map(button => {
								const { id } = button

								return (
									<div key={id} css={buttonStyles}>
										<Button {...button} />
									</div>
								)
							})}
						</ButtonsContainer>
					</TextContainer>
					<MediaContainer css={getMediaAlign(mediaAlign)} data-element={elementData.bannerMediaContainer}>
						{media ? (
							<MediaWrapper css={getMediaSize(mediaSize)} data-element={elementData.bannerMedia}>
								<Media
									imgStyle={{
										objectFit: "contain",
									}}
									{...media}
								/>
							</MediaWrapper>
						) : null}
					</MediaContainer>
				</Container>
				{additionalText && (
					<Container>
						<AdditionalText
							sectionTheme={theme}
							dangerouslySetInnerHTML={{ __html: additionalText?.childMarkdownRemark.html }}
						/>
					</Container>
				)}
				{cards && cards.length ? (
					<Container css={cardsContainerStyles}>
						{(cards || []).map(card => {
							const { id } = card
							return <Card key={id} {...card} />
						})}
					</Container>
				) : null}
			</Cover>
		</section>
	)
}

export default React.memo(ImageRight)
